import request from './request.js'

// 登录注册接口

//海量数据服务协议
export function agreement(){
	return request({
		url:'/api/th-txt/agreement',
		method:'get',
	})
}

//隐私政策
export function privacyPolicy(){
	return request({
		url:'/api/th-txt/privacy-policy',
		method:'get',
	})
}

//登录接口
export function accountLogin(_data){
	return request({
		url:'/api/login/account-login?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//检查手机号或邮箱以及图片验证码是否正确
export function checkMobilDraw(_data){
	return request({
		url:'/api/login/check-mobil-draw?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//检查手机号/邮箱 和 短信验证码是否正确
export function verification(_data){
	return request({
		url:'/api/login/check-mobile-verification-code?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//根据验证码ID获取图片
export function draw(_data){
	return request({
		url:'/api/login/draw/'+ _data,
		method:'get',
		responseType: "blob",
	})
}

//手机号找回提交修改密码
export function editAccountPassword(_data){
	return request({
		url:'/api/login/edit-account-password?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//初始化图片验证码
export function init(){
	return request({
		url:'/api/login/init',
		method:'get',
	})
}

//上传工单详情附件
export function addAttachment(_data,id){
	return request({
		url:'/api/index/add/attachment/'+id,
		data: _data,
		method:'post',
	})
}

// //上传工单详情附件
// export function addAttachment(_data,id){
// 	return request({
// 		url:'/api/index/add/attachment/'+id,
// 		data: _data,
// 		method:'post',
// 	})
// }

//图片上传
export function picUpload(_data){
	return request({
		url:'/api/login/pic-upload',
		data: _data,
		method:'post',
	})
}

//上传头像
export function headUpload(_data){
	return request({
		url:'/api/center/pic/upload',
		data: _data,
		method:'post',
	})
}

//注册用户
export function registered(_data){
	return request({
		url:'/api/login/registered-account?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//发送短信验证码
export function sendVerificationCode(_data){
	return request({
		url:'/api/login/send-verification-code?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//使用邮箱获取验证码
export function sendResetPasswordEmail(_data){
	return request({
		url:'/api/login/send-reset-password-email?data='+ JSON.stringify(_data),
		method:'get',
	})
}


// 知识文档接口

//知识文档一级分类
export function categoryList(){
	return request({
		url:'/api/knowledge/category-list',
		method:'get',
	})
}

//知识文档详情 {knowledgeId}
export function detailPageInfo(_data){
	return request({
		url:'/api/knowledge/detail-page-info/'+ _data,
		method:'get',
	})
}

//用户收藏夹列表 {documentId}
export function favoritesList(_data){
	return request({
		url:'/api/knowledge/favorites-list/'+ _data,
		method:'get',
	})
}

//获取搜索内容下的知识文档所属分类列表
export function getKnowledgeInCategoryList(_data){
	return request({
		url:'/api/knowledge/get-knowledge-in-category-list?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//搜索【模糊推荐】知识文档
export function recommended(_data){
	return request({
		url:'/api/knowledge/search-knowledge-lazy-recommended?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//模糊搜索知识文档列表结果
export function searchKnowledgeResult(_data){
	return request({
		url:'/api/knowledge/search-knowledge-result?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//三级分类下的知识文档列表
export function threeCategoryKnowledgeList(_data){
	return request({
		url:'/api/knowledge/three-category-knowledge-list?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//一级分类下的二三级分类 {oneCategoryId}
export function twoThreeCategoryList(_data){
	return request({
		url:'/api/knowledge/two-three-category-list/'+ _data,
		method:'get',
	})
}


// 系统、验证 消息接口

//用户消息列表
export function accountMsgRecord(_data){
	return request({
		url:'/api/msg/account-msg-record?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//消息详情 {id}
export function msgDetail(_data){
	return request({
		url:'/api/msg/msg-detail/'+ _data,
		method:'get',
	})
}

//删除消息 {id}
export function removeMsg(_data){
	return request({
		url:'/api/msg/remove-msg/'+ _data,
		method:'post',
	})
}


// 账号中心接口

//账号中心基本数据
export function accountBaseInfo(){
	return request({
		url:'/api/center/account-base-info',
		method:'get',
	})
}

//用户收藏夹信息
export function accountFavorites(_data){
	return request({
		url:'/api/center/account-favorites?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//新增绑定CSI号
export function addBindingCsi(_data){
	return request({
		url:'/api/center/add-binding-csi?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//添加收藏夹
export function addFavorites(_data){
	return request({
		url:'/api/center/add-favorites?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//绑定CSI详情 {bindingId}
export function bindingCsiInfo(_data){
	return request({
		url:'/api/center/binding-csi-detail-info/'+ _data,
		method:'get',
	})
}

//CAU操作绑定用户/{bindingId}/{type}
//  【bindingId绑定id、type类型1同意、2拒绝、3删除】
export function bindingAcount(id,type){
	return request({
		url:'/api/center/cau-operation-binding-account/'+ id +'/'+type,
		method:'post',
	})
}

//更改收藏夹名称
export function changeFavoritesName(_data){
	return request({
		url:'/api/center/change-favorites-name?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//更换头像 headPic
export function changeHeadPic(_data){
	return request({
		url:'/api/center/change-head-pic?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//手机换绑-验证原手机号和验证码
export function changeMobile(_data){
	return request({
		url:'/api/center/change-mobile?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//手机换绑-立即绑定新手机号
export function changeNewMobile(_data){
	return request({
		url:'/api/center/change-new-mobile?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//编辑用户名
export function changeUsername(_data){
	return request({
		url:'/api/center/change-username?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//分配CAU给其他人--->用户列表{bindingId}
export function othersBindingCsi(_data){
	return request({
		url:'/api/center/distribution-others-binding-csi-account-list/'+ _data,
		method:'get',
	})
}

//CAU确认分配给他人/{bindingId}/{accountId}
export function distributionOthers(id1,id2){
	return request({
		url:'/api/center/distribution-others/'+ id1+'/'+id2,
		method:'post',
	})
}

//用户收藏夹列表
export function getAccountFavorites(){
	return request({
		url:'/api/center/get-account-favorites',
		method:'get',
	})
}

//删除收藏夹{id}
export function removeFavorites(_data){
	return request({
		url:'/api/center/remove-favorites/'+ _data,
		method:'post',
	})
}

//解除绑定CSI号{bindingId}
export function unbindCsi(_data){
	return request({
		url:'/api/center/unbind-csi/'+ _data,
		method:'post',
	})
}

// 用户收藏知识文档
export function accountCollect(_data){
	return request({
		url:'/doc/manager/account-collect?data='+ JSON.stringify(_data),
		method:'post',
	})
}