import request from './request.js'
import axios from 'axios'

//获取所有一级分类
export function getOneCategoty(num){
	return request({
		url:'/api/login/get-one-categoty/'+num,
		method:'get',
	})
}

//获取一级分类下的二级分类列表
export function getTwoCategory(_data){
	return request({
		url:'/api/login/get-two-category/'+ _data,
		method:'get',
	})
}

//获取二级分类下的三级分类列表
export function getThreeCategory(_data){
	return request({
		url:'/api/login/get-three-category/'+ _data,
		method:'get',
	})
}

//用户工单列表-非技术工单列表
export function workOrderListNormal(_data){
	return request({
		url:'/api/index/account-work-order-list-normal?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//用户工单列表-技术工单列表
export function workOrderListTechnology(_data){
	return request({
		url:'/api/index/account-work-order-list-technology?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//首页公司订单统计
export function orderStatistical(){
	return request({
		url:'/api/index/company-work-order-statistical',
		method:'get',
	})
}

//获取客户服务号列表
export function customerServiceNo(){
	return request({
		url:'/api/index/get-customer-service-no',
		method:'get',
	})
}

//获取硬件平台、操作系统、部署方式
export function deployment(_data){
	return request({
		url:'/api/index/get-hardware-system-deployment?data='+ JSON.stringify(_data),
		method:'get',
	})
}

//获取产品名称、版本信息
export function nameVersion(){
	return request({
		url:'/api/index/get-product-name-version',
		method:'get',
	})
}

//获取问题类型{twoCategoryId}
export function problemCategory(_data){
	return request({
		url:'/api/index/get-product-name-version-problem-category/'+_data,
		method:'get',
	})
}

//获取操作系统-->版本-->系统语言{id}
export function language(_data){
	return request({
		url:'/api/index/get-system-version-language/'+_data,
		method:'get',
	})
}

//获取操作系统-->版本{id}
export function version(_data){
	return request({
		url:'/api/index/get-system-version/'+_data,
		method:'get',
	})
}

//提交工单
export function submitWorkOrder(_data,count){
	return request({
		url:'/api/index/submit-work-order?data='+ JSON.stringify(_data),
		method:'post',
		data:count
	})
}

//工单详情
export function workOrderDetail(_data){
	return request({
		url:'/api/index/work-order-detail/'+_data,
		method:'get',
	})
}

//工单详情-获取工单沟通记录
export function communicationRecord(_data){
	return request({
		url:'/api/index/work-order-detail-communication-record?data='+ JSON.stringify(_data),
		method:'get',
	})
}

// 下载txt、pdf、img
export function download(_data){
	return axios({
		method: 'get',
		url: `${_data}`,
		headers:  {'Content-Type': 'multipart/form-data;charset=UTF-8'},
		responseType: "arraybuffer",
	});
}

//产品分类
export function productList(){
	return request({
		url:'/api/index/product-list',
		method:'get',
	})
}

//版本分类
export function productVersionList(_data){
	return request({
		url:'/api/index/product-version-list/'+_data,
		method:'get',
	})
}

//收藏工单、取消收藏
export function collectionWorkOrder(_data){
	return request({
		url:'/api/index/collection-work-order/'+_data,
		method:'post',
	})
}

// 调整问题严重等级
export function changeSeverity(_data){
	return request({
		url:'/api/index/change-work-order-problem-severity?data='+ JSON.stringify(_data),
		method:'post',
	})
}

// 添加工单附件
export function createAttach(_data){
	return request({
		url:'/api/index/create-work-order-attach?data='+ JSON.stringify(_data),
		method:'post',
	})
}

// 用户-添加工单反馈
export function createFeedback(_data,html){
	return request({
		url:'/api/index/create-work-order-feedback?data='+ JSON.stringify(_data),
		method:'post',
		data: html
	})
}

// 用户工单升级
export function upgradeOrder(_data){
	return request({
		url:'/api/index/account-upgrade-work-order?data='+ JSON.stringify(_data),
		method:'post',
	})
}

// 用户重开工单
export function openAgain(_data){
	return request({
		url:'/api/index/open-again?data='+ JSON.stringify(_data),
		method:'post',
	})
}

//查看工单升级原因
export function upgradeReason(_data){
	return request({
		url:'/api/index/get-work-order-upgrade-reason/'+_data,
		method:'get',
	})
}

// 用户修改工单联系人信息
export function changeOrder(_data){
	return request({
		url:'/api/index/account-change-work-order-contact?data='+ JSON.stringify(_data),
		method:'post',
	})
}

// 提交关闭工单申请
export function closeOrder(_data,html){
	return request({
		url:'/api/index/submit-close-work-order-apply?data='+ JSON.stringify(_data),
		method:'post',
		data:{ closeFeedback: html } 
	})
}








